import { isFunction, isApp, getEnv } from '@bbtfe/utils';

class Sender {
  constructor(options = {}) {
    this.version = options.version;
    this.debug = options.debug;
    this.env = getEnv();
    this.maxBufferSize = options.maxBufferSize || 0;
    this.maxTimeout = options.maxTimeout || 5000;
    this.trackUrl = `//tracking.babytree${
      this.env == 'prod' ? '' : '-test'
    }.com/warlock-collector/service/T0003`;
    this.filterKeys = [
      'ti',
      'sv',
      'px',
      'ts',
      'la',
      'lo',
      'gl',
      'nt',
      'isConnected',
      'sd',
      'os',
      'ov',
      'db',
      'dm',
      'dr',
      'ai',
      'bu',
      'pw',
      'ch',
      'mac',
      'imsi',
      'pr',
      'imei'
    ];
    this.buffer = [];
    this.timerId = null;
  }

  imgLog(url) {
    window.imgLogData || (window.imgLogData = {});
    const uid = +new Date();
    let img = (window.imgLogData[uid] = new Image());
    img.onload = img.onerror = function () {
      //用完销毁
      img.onload = img.onerror = null;
      img = null;
      delete window.imgLogData[uid];
    };
    img.src = url;
  }

  _checkData(data = {}) {
    if (this.env == 'prod') return true;
    if (!data.bpi || !data.pt || !data.an) {
      console.error(`请检查bpi,pt,an的正确性`);
      return false;
    }
    const reg = /(^ )|( $)/;
    if (reg.test(data.bpi) || reg.test(data.pt) || reg.test(data.an)) {
      console.error(`请检查bpi,pt,an的正确性`);
      return false;
    }
    return true;
  }

  send(data) {
    if (!data) {
      return;
    }
    if (this._checkData(data)) {
      if (!data.pi) {
        data.pi = data.pt;
      }
      // app内 包装到ready中执行
      if (isApp()) {
        this._ready(() => {
          if (this.nativeSend(data)) {
            return;
          }
          this.webSend(data);
        });
      } else {
        if (this.maxBufferSize == 0) {
          this.webSend(data);
        } else {
          this.pushBuffer(data);
        }
      }
    }
  }

  pushBuffer(data) {
    this.buffer.push(data);
    if (this.buffer.length >= this.maxBufferSize) {
      this.flush();
    } else if (data.an == '2') {
      this.flush();
    } else if (!this.timerId) {
      this.timerId = window.setTimeout(() => {
        this.flush();
      }, this.maxTimeout);
    }
  }

  flush() {
    if (this.buffer.length) {
      this.webSend(this.buffer);
      this.buffer.length = 0;
    }
    if (this.timerId) {
      window.clearTimeout(this.timerId);
      this.timerId = null;
    }
  }

  _ready(callback) {
    // app外  不存在 window.bbtNative.ready
    if (!window.bbtNative || (window.bbtNative && !window.bbtNative.ready)) {
      // 如果没有引入bbtNative 自己包装一个window.bbtNative.ready
      window.bbtNative = window.bbtNative || {};
      window.bbtNative.ready = function (cb) {
        cb && cb();
      };
    }
    window.bbtNative.ready(() => {
      callback && callback();
    });
  }

  _stringify(data) {
    return JSON.stringify(data, (key, value) => {
      if (typeof value === 'number') {
        return String(value);
      }
      if (value === '' || !value) {
        return;
      }
      return value;
    });
  }

  filterData(data) {
    const newData = {
      ...data
    };
    this.filterKeys.forEach(key => {
      try {
        //兼容美囤px=3时不做删除处理
        if (key == 'px' && data.px == '3') {
        } else {
          delete newData[key];
        }
      } catch (error) {
        window.console.log(error);
      }
    });
    return newData;
  }

  nativeSend(data = {}) {
    try {
      // ios WKWebview
      if (
        window.Bridge &&
        window.Bridge.postMessage &&
        isFunction(window.Bridge.postMessage)
      ) {
        if (this.debug) {
          console.log('WKWebview埋点:', this._stringify(this.filterData(data)));
        }
        window.Bridge.postMessage({
          method: 'BIDataDriver_HandleBIDataWithDictionary',
          params: [JSON.parse(this._stringify(this.filterData(data)))]
        });
        return true;
      }
      // android
      if (
        window.track &&
        window.track.addTrackdata &&
        isFunction(window.track.addTrackdata)
      ) {
        if (this.debug) {
          console.log(
            'track.addTrackdata埋点:',
            this._stringify(this.filterData(data))
          );
        }
        window.track.addTrackdata(this._stringify(this.filterData(data)));
        return true;
      }
      // android
      if (
        window.android &&
        window.android.track &&
        window.android.track.addTrackdata &&
        isFunction(window.android.track.addTrackdata)
      ) {
        if (this.debug) {
          console.log(
            'android。track.addTrackdata埋点:',
            this.filterData(data)
          );
        }
        window.android.track.addTrackdata(
          this._stringify(this.filterData(data))
        );
        return true;
      }
      // ios UIWebview 这个切记要放到最后面判断  因为在某些旧版本安卓手机会命中这个function，调用就崩溃
      if (
        window.BIDataDriver_HandleBIDataWithDictionary &&
        isFunction(window.BIDataDriver_HandleBIDataWithDictionary)
      ) {
        if (this.debug) {
          console.log('UIWebview埋点:', this._stringify(this.filterData(data)));
        }
        window.BIDataDriver_HandleBIDataWithDictionary(
          JSON.parse(this._stringify(this.filterData(data)))
        );
        return true;
      }
    } catch (error) {
      window.console.error(error);
    }
    return false;
  }

  webSend(data) {
    if (this.sendBeacon(data)) {
      return;
    }
    this.httpPost(data);
  }

  sendBeacon(data) {
    if (window.navigator.sendBeacon) {
      if (this.debug) {
        console.log('sendBeacon埋点:', this._stringify(this.getData(data)));
      }
      window.navigator.sendBeacon(
        this.trackUrl,
        this._stringify(this.getData(data))
      );
      return true;
    }
    return false;
  }

  httpPost(data, isAsync = true) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', this.trackUrl, isAsync);
    xhr.setRequestHeader('Content-Type', 'text/plain');
    if (this.debug) {
      console.log('httpPost埋点:', this._stringify(this.getData(data)));
    }
    xhr.send(this._stringify(this.getData(data)));
    return true;
  }

  getData(data) {
    return {
      head: {
        version: this.version,
        appid: 'BRS-0000-0005',
        sign: 'fc35fdc70d5fc69d269883a822c7a53e' // MD5 ("html")
      },
      body: {
        ja:
          Object.prototype.toString.call(data) === '[object Array]'
            ? data
            : [data]
      }
    };
  }
}

export default Sender;
