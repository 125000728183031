import { getQuery } from '@bbtfe/utils';

class Heart {
  constructor(tracking, options = {}) {
    this.tracking = tracking;
    this.DURATION = options.heartbeatDuration || 3000; // 心跳间隔
    this.isSend38601 = !!options.isSend38601;
    this.data = options.heartbeatData || options.data || {}; // 心跳间隔
    this.heartTimerId = null;
    this.tabHiddenTime = null;
    this.debug = options.debug;
    this.listener = this.listener.bind(this);
    document.addEventListener('visibilitychange', this.listener, false);
  }

  send() {
    if (this.debug) {
      console.log('心跳');
    }
    this.tracking.send(this.getData());
  }

  getData() {
    let strBe = '';
    if (this.isSend38601) {
      strBe = [
        'action_duration_n=' + this.DURATION,
        'belong_pt=' + (this.data.pt ? this.data.pt : ''), // 业务页面pt，用于上报停留时长字段belong_pt取值
        'trace_id=' +
        (getQuery('trace_id') !== undefined ? getQuery('trace_id') : '')
      ].join('$'); // 取url中的trace_id字段，用于上报停留时长字段trace_id取值
      return {
        ...this.data,
        an: '0',
        bpi: '38601', // 固定写死,统一所有页面页面停留时长埋点
        pi: 'GL_PT_DURATION', // 固定写死,统一所有页面页面停留时长埋点
        pt: 'GL_PT_DURATION', // 固定写死,统一所有页面页面停留时长埋点
        be: this.data.be ? this.data.be + '$' + strBe : strBe
      };
    } else {
      strBe = [
        'log_action=' + 'uaaction_' + +new Date(), // 约定用来判断停留事件是哪一次打开页面带来的停留时长
        'action_duration=' + this.DURATION,
        'trace_id=' +
        (getQuery('trace_id') !== undefined ? getQuery('trace_id') : '')
      ].join('$');
      return {
        ...this.data,
        an: '0',
        be: this.data.be ? this.data.be + '$' + strBe : strBe
      };
    }
  }

  start() {
    this.heartTimerId = window.setTimeout(() => {
      this.send();
      this.start();
    }, this.DURATION);
  }

  stop() {
    if (this.heartTimerId) {
      window.clearTimeout(this.heartTimerId);
      this.tabHiddenTime = +new Date();
    }
  }

  listener() {
    if (document.visibilityState === 'hidden') {
      if (this.debug) {
        console.log('心跳:停止');
      }
      // 页面隐藏后停止心跳
      this.stop();
    }
    if (document.visibilityState === 'visible') {
      // 页面显示后，判断距离上次隐藏是否在10分钟之内，如果在10分钟之内，继续心跳
      if (+new Date() - this.tabHiddenTime < 10 * 60 * 1000) {
        if (this.debug) {
          console.log('心跳:10分钟内，恢复心跳');
        }
        this.start();
      } else {
        if (this.debug) {
          console.log('心跳:10分钟外，不恢复心跳');
        }
      }
    }
  }

  destroy() {
    if (this.heartTimerId) {
      window.clearTimeout(this.heartTimerId);
    }
    document.removeEventListener('visibilitychange', this.listener);
  }
}

export default Heart;
