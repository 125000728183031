import 'intersection-observer';
// 滚动曝光
class Exposure {
  constructor(tracking, options = {}) {
    this.tracking = tracking;
    this.debug = options.debug;
    this._observer = null;
  }

  isIntersecting(entry) {
    if (entry.isIntersecting && entry.intersectionRatio >= 0) return true;
    // vivo某手机在app内 entry.isIntersecting 竟然是 undefined 跪服
    if (entry.isIntersecting === undefined) return true;
    return false;
  }

  setObserver() {
    if (this._observer) return;
    this._observer = new IntersectionObserver(
      (entries) => {
        if (!entries.length) return;
        entries.forEach((entry) => {
          const target = entry.target;
          const exposure = target.__exposure;
          if (exposure.count < exposure.max && this.isIntersecting(entry)) {
            exposure.count++;
            if (this.debug) {
              console.log('曝光:', target, `第${exposure.count}次曝光`);
            }
            const data = exposure.data;
            if (exposure.autoExposure) {
              this.tracking.send(data);
            }
            const callback = exposure.callback;
            if (callback) {
              callback(target, data);
            }
          }
        });
      },
      {
        root: null
      }
    );
  }

  observer(options = {}) {
    let {
      exposureType = 'multi', //曝光类型：反复曝光multi/单次曝光one 默认反复曝光
      callBack, //回调函数
      callback,
      ele, //滚动的元素数据, [{el: 元素, data: {bpi: '', an: '' }}]
      configList,
      autoExposure = true
    } = options;
    const realCallback = callback || callBack;
    const realConfigList = configList || ele || [];
    if (!this._observer) {
      this.setObserver();
    }
    if (realConfigList && realConfigList.length) {
      realConfigList.forEach((config = {}) => {
        const dom = config.dom || config.el;
        if (!dom) return;
        dom.__exposure = {
          data: config.data || {},
          count: 0,
          max: exposureType === 'one' ? 1 : Number.MAX_VALUE,
          callback: realCallback,
          autoExposure: autoExposure ? true : false
        };
        this._observer.observe(config.el);
      });
    }
  }

  // 终止对所有目标元素可见性变化的观察
  disconnect() {
    this._observer.disconnect();
  }

  // 停止对一个元素的观察
  unobserve(elements) {
    if (!elements) {
      return;
    }
    if (elements instanceof Node) {
      this._observer.unobserve(elements);
    }
    if (elements instanceof NodeList) {
      const targetArr = Array.prototype.slice.call(elements);
      targetArr.forEach((element) => {
        this._observer.unobserve(element);
      });
    }
  }

  destroy() {
    this._observer.disconnect();
    this._observer = null;
  }
}

export default Exposure;
