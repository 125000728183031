import Sender from './sender';
import Heart from './heart';
import Exposure from './exposure';
import uaParser from './uaParser';
import { getQuery, getNetInfo, getUid } from '@bbtfe/utils';

// 产品名称，和凌丽华商定的代表app_id的px字段，跟“社区埋点字段细则”中为wap分配的“app_id”不一致
const APP_ID = '21';

// {'android': 1, 'ios': 2, 'windowsphone': 3, 'other': 6, windows': 7, 'mac': 8}
const OS_INFO = {
  Android: 1,
  iOS: 2,
  'Windows Phone': 3,
  Windows: 7,
  'Mac OS': 8
};

class Tracking {
  constructor(options = {}) {
    this.data = options.data || {};
    this.version = '0.3.13';
    this.debug = options.debug || false;
    this.sender = new Sender({
      debug: this.debug,
      version: this.version,
      maxBufferSize: options.maxBufferSize || 0,
      maxTimeout: options.maxTimeout || 5000
    });
    // this.uaParser = new UAParser();
    this.geolocation = false;
    this.heart = null;
    this.exposure = null;
    this.autoClick = !!options.autoClick || false;
    if (this.autoClick) {
      this.clickListener = this.clickListener.bind(this);
      window.addEventListener('click', this.clickListener, true);
    }
  }

  getGeolocationInfo() {
    this.data.la = ''; // 经度
    this.data.lo = ''; // 维度
    this.data.gl = ''; // 经纬度详细地址
    this.data.ip = ''; // 访问ip
    try {
      this.data.la = window.parseFloat(
        getQuery('babytree-app-latitude') || '0'
      );
      this.data.lo = window.parseFloat(
        getQuery('babytree-app-longitude') || '0'
      );
      return;
    } catch (error) {
      this.data.la = '';
      this.data.lo = '';
    }
    window.navigator.geolocation.getCurrentPosition((position) => {
      const latitude = Number(position.coords.latitude).toFixed(6);
      const longitude = Number(position.coords.longitude).toFixed(6);
      this.data.la = latitude + '' || '';
      this.data.lo = longitude + '' || '';
    });
  }

  getRefererPageInfo() {
    return {
      rp: this.data.pi || '',
      rt: this.data.pt || '',
      rf: this.data.pv || '',
      ru: this.data.url || document.referrer
    };
  }

  getMetaInfo() {
    const now = Date.now() + '';
    return {
      ti: now, // 同App SDK
      px: APP_ID, // 产品名称
      ts: now, // 时间戳
      sv: this.version // 本SDK版本
    };
  }

  getDeviceInfo() {
    const deviceInfo = uaParser.parser();
    return {
      sr: String(window.navigator.userAgent), // 其他扩展字段，（ua）
      nt: String(getNetInfo()), // 网络状态，如wifi等
      os: String(OS_INFO[deviceInfo.OS.name] || '6'), // 平台信息，Android或iOS
      ov: String(deviceInfo.OS.version), // 平台信息，操作系统版本
      db: String(''), // 设备品牌
      dm: String(''), // 机型
      br: String(deviceInfo.BROWSER.name), // 浏览器名称
      bv: String(deviceInfo.BROWSER.version), // 浏览器版本
      dr: String(`${window.screen.width}x${window.screen.height}`), // 分辨率，"1080x1920"
      ud: String(getUid()) // 生成的唯一id，用于统计UV
    };
  }

  getData(data = {}) {
    return {
      ...this.getMetaInfo(),
      ...this.getDeviceInfo(),
      ...this.getRefererPageInfo(),
      url: location.href, // 当前页面URL
      ...this.data,
      ...data
    };
  }

  config(options = {}) {
    if (options && options.geolocation === true) {
      this.getGeolocationInfo();
      delete options.geolocation;
    }

    if (options.type == 'image') {
      // 用来验证image统计与ajax统计gap，验证结束后去掉代码
      this.sender.imgLog(
        '//m.babytree.com/track/test.gif' + '?ud=' + getUid()
      );
      delete options.type;
    }
    if (options && options.heartbeat) {
      this.registerHeart({
        ...options.heartbeatConfig,
        isSend38601: options.isSend38601
      });
      delete options.heartbeat;
      delete options.heartbeatConfig;
    }
    this.data = {
      ...this.data,
      ...options
    };
  }

  /**
   * flutter web 调用js埋点的方法
   * @param data
   */
  fluWebSend(data = '') {
    if(data) {
      const jsonData = JSON.parse(data)
      let newData = this.getData(jsonData);
      this.sender.send(newData);
    }
  }

  /**
   *
   * user_id: String(),              // 根据配置生成
   * bb: String(),
   * ii: String(),                   // 栏位ID
   * data-bbt-tracking-ii=""
   * ps: String(),                   // 区域内横向顺位，如左数第X个
   * po: String(),                   // 区域内纵向顺位，如向下第Y个
   * ci: String(),                   // 当前展示或点击的内容id或物料id
   * cs: String(),                   // 内容来源id
   * cy: String(),                   // 数据来源类型id
   * ct: String(),                   // 内容类型id
   * ae: String(),                   // 行为扩展字段
   * ce: String(),                   // 内容扩展字段
   * sr: String(),                   // 其他扩展字段，（覆盖ua）
   * ap: String(),                   // 事件参数id
   * ad: String(),                   // 页面停留时长
   */
  send(data = {}) {
    data = this.getData(data);
    this.sender.send(data);
  }

  webSend(data = {}) {
    data = this.getData(data);
    this.sender.webSend(data);
  }

  flush() {
    this.sender.flush();
  }

  clickListener(event) {
    const values = this.getOptions(this.getTarget(event));
    if (Object.keys(values || {}).length === 0) {
      return;
    }
    this.send({
      ...values,
      an: 2
    });
  }

  getTarget(event) {
    let target = event.target;
    if (!target) {
      target = event.srcElement || document;
    }
    if (target.nodeType === 3) {
      target = target.parentNode;
    }
    while (
      !Object.keys(target.dataset || {}).find(
        (attr) => attr.indexOf('bbtTracking') >= 0
      )
      ) {
      target = target.parentNode;
      if (target === document) {
        target = undefined;
        break;
      }
    }
    return target;
  }

  getOptions(target) {
    if (!target) {
      return {};
    }
    const flags = [
      'user_id',
      'bb',
      'ii',
      'ps',
      'po',
      'ci',
      'cs',
      'cy',
      'ct',
      'an',
      'ae',
      'ce',
      'sr',
      'ap',
      'ad'
    ];
    const dataset = target.dataset;
    return flags
      .map((flag) => {
        const attr = `bbtTracking${flag[0].toUpperCase() + flag.slice(1)}`;
        return {
          name: flag,
          value: (dataset[attr] || '').trim()
        };
      })
      .filter((item) => !!item.value)
      .reduce((accumulator, item) => {
        accumulator[item.name] = item.value;
        return accumulator;
      }, {});
  }

  /*
   * 注册曝光的元素
   * config = {
   *    scrollType: hor/ver,
   *    exposureType: multi/one,
   *    parentCon: ele,
   *    callBack: cb,
   *    ele: [ele],
   *    delay: int,
   * }
   * exposureType 曝光类型：反复曝光multi/单次曝光one 默认反复曝光
   * scrollType 滚动类型：横向/纵向 默认纵向
   * parentCon 滚动的父容器 默认document
   * callBack 回调函数
   * ele 滚动的元素
   * delay 延迟 默认300ms
   */
  registerExposure(config) {
    if (!this.exposure) {
      this.exposure = new Exposure(this, { debug: this.debug });
    }
    this.exposure.observer(config, this);
  }

  /*
   *清除曝光
   */
  clearExp() {
    if (this.exposure) {
      this.exposure.destroy();
      this.exposure = null;
    }
  }

  /*
   *删除曝光
   *con 父元素
   *eles 被删除的元素 如果没有责把con下的所有都删除 支持 ele / [ele] 格式
   */
  removeExp(con, eles) {
    this.exposure && this.exposure.unobserve(eles);
  }

  registerHeart(config) {
    this.heart = new Heart(this, {
      ...config,
      debug: this.debug
    });
    this.heart.start();
  }

  heartbeatStop() {
    if (this.heart) {
      this.heart.stop();
    }
  }

  /*
   *完读率 在文章后面增加1px的像素 对其进行单次曝光
   * config = {
   *    ele: el,
   *    data: object,
   *    callback: func,
   *    createRateDiv: func,
   *    callBack: func,
   * }
   * ele 包裹文章的element元素
   * data 发送的数据
   * callBack 回调函数
   * parentCon: 父亲容器 没有默认window
   * createRateDiv 自定义创建曝光点
   */
  finishRateReport(config = {}) {
    if (!config.ele) {
      throw new Error('文章容器不能为空');
    }
    let rateDiv;
    if (config.createRateDiv) {
      rateDiv = config.createRateDiv();
    } else {
      rateDiv = document.createElement('div');
      rateDiv.style.position = 'relative';
      rateDiv.style.left = '50%';
      rateDiv.style.width = '0';
      rateDiv.style.height = '0';
      config.ele.appendChild(rateDiv);
    }

    const tpara = {
      configList: [
        {
          el: rateDiv,
          data: config.data
        }
      ],
      exposureType: 'one',
      callback: config.callback || config.callBack,
      autoExposure: config.autoExposure
    };
    this.registerExposure(tpara);
  }

  destroy() {
    this.flush();
    if (this.heart) {
      this.heart.destroy();
      this.heart = null;
    }
    if (this.exposure) {
      this.exposure.destroy();
      this.exposure = null;
    }
    if (this.autoClick && this.clickListener) {
      document.removeEventListener('click', this.clickListener);
    }
  }
}

const tracking = new Tracking({
  autoClick: false
});

Tracking.fluWebSend = function (data) {
  tracking.fluWebSend(data);
};

Tracking.webSend = function (data) {
  tracking.webSend(data);
};

Tracking.send = function (data) {
  tracking.send(data);
};

Tracking.config = function (data) {
  tracking.config(data);
};

Tracking.registerExposure = function (data) {
  tracking.registerExposure(data);
};

Tracking.removeExp = function (con, eles) {
  tracking.removeExp(con, eles);
};

Tracking.clearExp = function () {
  tracking.clearExp();
};

Tracking.finishRateReport = function (config) {
  tracking.finishRateReport(config);
};

Tracking.registerHeart = function (config) {
  tracking.registerHeart(config);
};

Tracking.heartbeatStop = function () {
  tracking.heartbeatStop();
};

export default Tracking;
