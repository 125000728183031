const cookie = {
    getRaw: function (key) {
        var reg = new RegExp('(^| )' + key + '=([^;]*)(;|\x24)'), result = reg.exec(document.cookie);
        if (result) {
            return result[2] || null;
        }
        return null;
    },
    get: function (key) {
        var value = cookie.getRaw(key);
        if ('string' == typeof value) {
            value = decodeURIComponent(value);
            return value;
        }
        return null;
    },
    setRaw: function (key, value, options) {
        options = options || {};
        var expires = options.expires;
        if ('number' == typeof options.expires) {
            expires = new Date();
            expires.setTime(expires.getTime() + options.expires);
        }
        document.cookie =
            key +
                '=' +
                value +
                (options.path ? '; path=' + options.path : '; path=/') +
                (expires ? '; expires=' + expires.toGMTString() : '') +
                (options.domain ? '; domain=' + options.domain : '') +
                (options.secure ? '; secure' : '');
    },
    set: function (key, value, options) {
        cookie.setRaw(key, encodeURIComponent(value), options);
    },
    remove: function (key, options) {
        options = options || {};
        options.expires = new Date(0);
        cookie.setRaw(key, '', options);
    }
};
const na = navigator, ws = window.screen;
const hash = function (str) {
    let h = 0, g = 0;
    for (let i = str.length - 1; i >= 0; i--) {
        let c = parseInt(`${str.charCodeAt(i)}`);
        h = ((h << 6) & 0xfffffff) + c + (c << 14);
        if ((g = h & 0xfe00000) != 0)
            h = h ^ (g >> 21);
    }
    return h;
};
const _getUid = function () {
    let str = [
        na.appName,
        na.version,
        na.language || na.browserLanguage,
        na.platform,
        na.userAgent,
        ws.width,
        'x',
        ws.height,
        ws.colorDepth,
        document.referrer || ''
    ].join(''), len = str.length, hLen = history.length;
    while (hLen > 0) {
        str += hLen-- ^ len++;
    }
    return (Math.round(Math.random() * 2147483647) ^ hash(str)) * 2147483647; // 2147483647 = 2的31次方-1
};
function getDomain() {
    //此方法不太严谨，后续需要升级，目前一级域名只有babytree.com, babytree-test.com, babytree-dev.com几种情况，
    return location.hostname.split('.').slice(-2).join('.');
}
const cookieUd = 'bbt_ud';
export const getUid = function () {
    var bbtUd = cookie.get(cookieUd);
    if (!bbtUd || bbtUd.split('.').length <= 1) {
        bbtUd = [_getUid(), +new Date() + Math.random() + Math.random()].join('.');
        cookie.set(cookieUd, bbtUd, {
            domain: getDomain(),
            expires: 10 * 365 * 24 * 60 * 60 * 1000
        });
    }
    return bbtUd;
};
