var EMPTY = '',
  UNKNOWN = '?',
  FUNC_TYPE = 'function',
  OBJ_TYPE = 'object',
  STR_TYPE = 'string',
  NAME = 'name',
  VERSION = 'version';

var util = {
  extend: function (regexes, extensions) {
    var mergedRegexes = {};
    for (var i in regexes) {
      if (extensions[i] && extensions[i].length % 2 === 0) {
        mergedRegexes[i] = extensions[i].concat(regexes[i]);
      } else {
        mergedRegexes[i] = regexes[i];
      }
    }
    return mergedRegexes;
  },
  has: function (str1, str2) {
    if (typeof str1 === 'string') {
      return str2.toLowerCase().indexOf(str1.toLowerCase()) !== -1;
    } else {
      return false;
    }
  },
  lowerize: function (str) {
    return str.toLowerCase();
  },
  major: function (version) {
    return typeof version === STR_TYPE
      ? version.replace(/[^\d\.]/g, '').split('.')[0]
      : undefined;
  },
  trim: function (str) {
    return str.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
  }
};

///////////////
// Map helper
//////////////

var mapper = {
  rgx: function (ua, arrays) {
    var i = 0,
      j,
      k,
      p,
      q,
      matches,
      match;

    // loop through all regexes maps
    while (i < arrays.length && !matches) {
      var regex = arrays[i], // even sequence (0,2,4,..)
        props = arrays[i + 1]; // odd sequence (1,3,5,..)
      j = k = 0;

      // try matching uastring with regexes
      while (j < regex.length && !matches) {
        matches = regex[j++].exec(ua);

        if (!!matches) {
          for (p = 0; p < props.length; p++) {
            match = matches[++k];
            q = props[p];
            // check if given property is actually array
            if (typeof q === OBJ_TYPE && q.length > 0) {
              if (q.length == 2) {
                if (typeof q[1] == FUNC_TYPE) {
                  // assign modified match
                  this[q[0]] = q[1].call(this, match);
                } else {
                  // assign given value, ignore regex match
                  this[q[0]] = q[1];
                }
              } else if (q.length == 3) {
                // check whether function or regex
                if (typeof q[1] === FUNC_TYPE && !(q[1].exec && q[1].test)) {
                  // call function (usually string mapper)
                  this[q[0]] = match ? q[1].call(this, match, q[2]) : undefined;
                } else {
                  // sanitize match using given regex
                  this[q[0]] = match ? match.replace(q[1], q[2]) : undefined;
                }
              } else if (q.length == 4) {
                this[q[0]] = match
                  ? q[3].call(this, match.replace(q[1], q[2]))
                  : undefined;
              }
            } else {
              this[q] = match ? match : undefined;
            }
          }
        }
      }
      i += 2;
    }
  },

  str: function (str, map) {
    for (var i in map) {
      // check if array
      if (typeof map[i] === OBJ_TYPE && map[i].length > 0) {
        for (var j = 0; j < map[i].length; j++) {
          if (util.has(map[i][j], str)) {
            return i === UNKNOWN ? undefined : i;
          }
        }
      } else if (util.has(map[i], str)) {
        return i === UNKNOWN ? undefined : i;
      }
    }
    return str;
  }
};

var maps = {
  browser: {
    oldsafari: {
      version: {
        '1.0': '/8',
        '1.2': '/1',
        '1.3': '/3',
        '2.0': '/412',
        '2.0.2': '/416',
        '2.0.3': '/417',
        '2.0.4': '/419',
        '?': '/'
      }
    }
  },

  os: {
    windows: {
      version: {
        ME: '4.90',
        'NT 3.11': 'NT3.51',
        'NT 4.0': 'NT4.0',
        '2000': 'NT 5.0',
        XP: ['NT 5.1', 'NT 5.2'],
        Vista: 'NT 6.0',
        '7': 'NT 6.1',
        '8': 'NT 6.2',
        '8.1': 'NT 6.3',
        '10': ['NT 6.4', 'NT 10.0'],
        RT: 'ARM'
      }
    }
  }
};

var browser = [
  [
    // Presto based
    /(opera\smini)\/([\w\.-]+)/i, // Opera Mini
    /(opera\s[mobiletab]{3,6}).+version\/([\w\.-]+)/i, // Opera Mobi/Tablet
    /(opera).+version\/([\w\.]+)/i, // Opera > 9.80
    /(opera)[\/\s]+([\w\.]+)/i // Opera < 9.80
  ],
  [NAME, VERSION],
  [
    /(opios)[\/\s]+([\w\.]+)/i // Opera mini on iphone >= 8.0
  ],
  [[NAME, 'Opera Mini'], VERSION],
  [
    /\s(opr)\/([\w\.]+)/i // Opera Webkit
  ],
  [[NAME, 'Opera'], VERSION],
  [
    // Mixed
    /(kindle)\/([\w\.]+)/i, // Kindle
    /(lunascape|maxthon|netfront|jasmine|blazer)[\/\s]?([\w\.]*)/i,
    // Lunascape/Maxthon/Netfront/Jasmine/Blazer
    // Trident based
    /(avant\s|iemobile|slim)(?:browser)?[\/\s]?([\w\.]*)/i,
    // Avant/IEMobile/SlimBrowser
    /(bidubrowser|baidubrowser)[\/\s]?([\w\.]+)/i, // Baidu Browser
    /(?:ms|\()(ie)\s([\w\.]+)/i, // Internet Explorer

    // Webkit/KHTML based
    /(rekonq)\/([\w\.]*)/i, // Rekonq
    /(chromium|flock|rockmelt|midori|epiphany|silk|skyfire|ovibrowser|bolt|iron|vivaldi|iridium|phantomjs|bowser|quark|qupzilla|falkon)\/([\w\.-]+)/i
    // Chromium/Flock/RockMelt/Midori/Epiphany/Silk/Skyfire/Bolt/Iron/Iridium/PhantomJS/Bowser/QupZilla/Falkon
  ],
  [NAME, VERSION],
  [
    /(konqueror)\/([\w\.]+)/i // Konqueror
  ],
  [[NAME, 'Konqueror'], VERSION],
  [
    /(trident).+rv[:\s]([\w\.]{1,9}).+like\sgecko/i // IE11
  ],
  [[NAME, 'IE'], VERSION],
  [
    /(edge|edgios|edga|edg)\/((\d+)?[\w\.]+)/i // Microsoft Edge
  ],
  [[NAME, 'Edge'], VERSION],
  [
    /(yabrowser)\/([\w\.]+)/i // Yandex
  ],
  [[NAME, 'Yandex'], VERSION],
  [
    /(Avast)\/([\w\.]+)/i // Avast Secure Browser
  ],
  [[NAME, 'Avast Secure Browser'], VERSION],
  [
    /(AVG)\/([\w\.]+)/i // AVG Secure Browser
  ],
  [[NAME, 'AVG Secure Browser'], VERSION],
  [
    /(puffin)\/([\w\.]+)/i // Puffin
  ],
  [[NAME, 'Puffin'], VERSION],
  [
    /(focus)\/([\w\.]+)/i // Firefox Focus
  ],
  [[NAME, 'Firefox Focus'], VERSION],
  [
    /(opt)\/([\w\.]+)/i // Opera Touch
  ],
  [[NAME, 'Opera Touch'], VERSION],
  [
    /((?:[\s\/])uc?\s?browser|(?:juc.+)ucweb)[\/\s]?([\w\.]+)/i // UCBrowser
  ],
  [[NAME, 'UCBrowser'], VERSION],
  [
    /(comodo_dragon)\/([\w\.]+)/i // Comodo Dragon
  ],
  [[NAME, /_/g, ' '], VERSION],
  [
    /(windowswechat qbcore)\/([\w\.]+)/i // WeChat Desktop for Windows Built-in Browser
  ],
  [[NAME, 'WeChat(Win) Desktop'], VERSION],
  [
    /(micromessenger)\/([\w\.]+)/i // WeChat
  ],
  [[NAME, 'WeChat'], VERSION],
  [
    /(brave)\/([\w\.]+)/i // Brave browser
  ],
  [[NAME, 'Brave'], VERSION],
  [
    /(whale)\/([\w\.]+)/i // Whale browser
  ],
  [[NAME, 'Whale'], VERSION],
  [
    /(qqbrowserlite)\/([\w\.]+)/i // QQBrowserLite
  ],
  [NAME, VERSION],
  [
    /(QQ)\/([\d\.]+)/i // QQ, aka ShouQ
  ],
  [NAME, VERSION],
  [
    /m?(qqbrowser)[\/\s]?([\w\.]+)/i // QQBrowser
  ],
  [NAME, VERSION],
  [
    /(baiduboxapp)[\/\s]?([\w\.]+)/i // Baidu App
  ],
  [NAME, VERSION],
  [
    /(2345Explorer)[\/\s]?([\w\.]+)/i // 2345 Browser
  ],
  [NAME, VERSION],
  [
    /(MetaSr)[\/\s]?([\w\.]+)/i // SouGouBrowser
  ],
  [NAME],
  [
    /(LBBROWSER)/i // LieBao Browser
  ],
  [NAME],
  [
    /xiaomi\/miuibrowser\/([\w\.]+)/i // MIUI Browser
  ],
  [VERSION, [NAME, 'MIUI Browser']],
  [
    /;fbav\/([\w\.]+);/i // Facebook App for iOS & Android with version
  ],
  [VERSION, [NAME, 'Facebook']],
  [
    /FBAN\/FBIOS|FB_IAB\/FB4A/i // Facebook App for iOS & Android without version
  ],
  [[NAME, 'Facebook']],
  [
    /safari\s(line)\/([\w\.]+)/i, // Line App for iOS
    /android.+(line)\/([\w\.]+)\/iab/i // Line App for Android
  ],
  [NAME, VERSION],
  [
    /headlesschrome(?:\/([\w\.]+)|\s)/i // Chrome Headless
  ],
  [VERSION, [NAME, 'Chrome Headless']],
  [
    /\swv\).+(chrome)\/([\w\.]+)/i // Chrome WebView
  ],
  [[NAME, /(.+)/, '$1 WebView'], VERSION],
  [/((?:oculus|samsung)browser)\/([\w\.]+)/i],
  [[NAME, /(.+(?:g|us))(.+)/, '$1 $2'], VERSION],
  [
    // Oculus / Samsung Browser

    /android.+version\/([\w\.]+)\s+(?:mobile\s?safari|safari)*/i // Android Browser
  ],
  [VERSION, [NAME, 'Android Browser']],
  [
    /(coc_coc_browser)\/([\w\.]+)/i // Coc Coc Browser
  ],
  [[NAME, 'Coc Coc'], VERSION],
  [
    /(sailfishbrowser)\/([\w\.]+)/i // Sailfish Browser
  ],
  [[NAME, 'Sailfish Browser'], VERSION],
  [
    /(chrome|omniweb|arora|[tizenoka]{5}\s?browser)\/v?([\w\.]+)/i
    // Chrome/OmniWeb/Arora/Tizen/Nokia
  ],
  [NAME, VERSION],
  [
    /(dolfin)\/([\w\.]+)/i // Dolphin
  ],
  [[NAME, 'Dolphin'], VERSION],
  [
    /(qihu|qhbrowser|qihoobrowser|360browser)/i // 360
  ],
  [[NAME, '360 Browser']],
  [
    /((?:android.+)crmo|crios)\/([\w\.]+)/i // Chrome for Android/iOS
  ],
  [[NAME, 'Chrome'], VERSION],
  [
    /(coast)\/([\w\.]+)/i // Opera Coast
  ],
  [[NAME, 'Opera Coast'], VERSION],
  [
    /fxios\/([\w\.-]+)/i // Firefox for iOS
  ],
  [VERSION, [NAME, 'Firefox']],
  [
    /version\/([\w\.]+)\s.*mobile\/\w+\s(safari)/i // Mobile Safari
  ],
  [VERSION, [NAME, 'Mobile Safari']],
  [
    /version\/([\w\.]+)\s.*(mobile\s?safari|safari)/i // Safari & Safari Mobile
  ],
  [VERSION, NAME],
  [
    /webkit.+?(gsa)\/([\w\.]+)\s.*(mobile\s?safari|safari)(\/[\w\.]+)/i // Google Search Appliance on iOS
  ],
  [[NAME, 'GSA'], VERSION],
  [
    /webkit.+?(mobile\s?safari|safari)(\/[\w\.]+)/i // Safari < 3.0
  ],
  [NAME, [VERSION, mapper.str, maps.browser.oldsafari.version]],
  [/(webkit|khtml)\/([\w\.]+)/i],
  [NAME, VERSION],
  [
    // Gecko based
    /(navigator|netscape)\/([\w\.-]+)/i // Netscape
  ],
  [[NAME, 'Netscape'], VERSION],
  [
    /(swiftfox)/i, // Swiftfox
    /(icedragon|iceweasel|camino|chimera|fennec|maemo\sbrowser|minimo|conkeror)[\/\s]?([\w\.\+]+)/i,
    // IceDragon/Iceweasel/Camino/Chimera/Fennec/Maemo/Minimo/Conkeror
    /(firefox|seamonkey|k-meleon|icecat|iceape|firebird|phoenix|palemoon|basilisk|waterfox)\/([\w\.-]+)$/i,

    // Firefox/SeaMonkey/K-Meleon/IceCat/IceApe/Firebird/Phoenix
    /(firefox)\/([\w\.]+)\s[\w\s\-]+\/[\w\.]+$/i, // Other Firefox-based
    /(mozilla)\/([\w\.]+)\s.+rv\:.+gecko\/\d+/i, // Mozilla

    // Other
    /(polaris|lynx|dillo|icab|doris|amaya|w3m|netsurf|sleipnir)[\/\s]?([\w\.]+)/i,
    // Polaris/Lynx/Dillo/iCab/Doris/Amaya/w3m/NetSurf/Sleipnir
    /(links)\s\(([\w\.]+)/i, // Links
    /(gobrowser)\/?([\w\.]*)/i, // GoBrowser
    /(ice\s?browser)\/v?([\w\._]+)/i, // ICE Browser
    /(mosaic)[\/\s]([\w\.]+)/i // Mosaic
  ],
  [NAME, VERSION]
];

var os = [
  [
    // Xbox, consider this before other Windows-based devices
    /(xbox);\s+xbox\s([^\);]+)/i, // Microsoft Xbox (360, One, X, S, Series X, Series S)

    // Windows based
    /microsoft\s(windows)\s(vista|xp)/i // Windows (iTunes)
  ],
  [NAME, VERSION],
  [
    /(windows)\snt\s6\.2;\s(arm)/i, // Windows RT
    /(windows\sphone(?:\sos)*)[\s\/]?([\d\.\s\w]*)/i, // Windows Phone
    /(windows\smobile|windows)[\s\/]?([ntce\d\.\s]+\w)/i
  ],
  [NAME, [VERSION, mapper.str, maps.os.windows.version]],
  [/(win(?=3|9|n)|win\s9x\s)([nt\d\.]+)/i],
  [
    [NAME, 'Windows'],
    [VERSION, mapper.str, maps.os.windows.version]
  ],
  [
    // Mobile/Embedded OS
    /\((bb)(10);/i // BlackBerry 10
  ],
  [[NAME, 'BlackBerry'], VERSION],
  [
    /(blackberry)\w*\/?([\w\.]*)/i, // Blackberry
    /(tizen|kaios)[\/\s]([\w\.]+)/i, // Tizen/KaiOS
    /(android|webos|palm\sos|qnx|bada|rim\stablet\sos|meego|sailfish|contiki)[\/\s-]?([\w\.]*)/i
    // Android/WebOS/Palm/QNX/Bada/RIM/MeeGo/Contiki/Sailfish OS
  ],
  [NAME, VERSION],
  [
    /(symbian\s?os|symbos|s60(?=;))[\/\s-]?([\w\.]*)/i // Symbian
  ],
  [[NAME, 'Symbian'], VERSION],
  [
    /\((series40);/i // Series 40
  ],
  [NAME],
  [
    /mozilla.+\(mobile;.+gecko.+firefox/i // Firefox OS
  ],
  [[NAME, 'Firefox OS'], VERSION],
  [
    // Google Chromecast
    /crkey\/([\d\.]+)/i // Google Chromecast
  ],
  [VERSION, [NAME, 'Chromecast']],
  [
    // Console
    /(nintendo|playstation)\s([wids34portablevu]+)/i, // Nintendo/Playstation

    // GNU/Linux based
    /(mint)[\/\s\(]?(\w*)/i, // Mint
    /(mageia|vectorlinux)[;\s]/i, // Mageia/VectorLinux
    /(joli|[kxln]?ubuntu|debian|suse|opensuse|gentoo|(?=\s)arch|slackware|fedora|mandriva|centos|pclinuxos|redhat|zenwalk|linpus)[\/\s-]?(?!chrom)([\w\.-]*)/i,
    // Joli/Ubuntu/Debian/SUSE/Gentoo/Arch/Slackware
    // Fedora/Mandriva/CentOS/PCLinuxOS/RedHat/Zenwalk/Linpus
    /(hurd|linux)\s?([\w\.]*)/i, // Hurd/Linux
    /(gnu)\s?([\w\.]*)/i // GNU
  ],
  [NAME, VERSION],
  [
    /(cros)\s[\w]+\s([\w\.]+\w)/i // Chromium OS
  ],
  [[NAME, 'Chromium OS'], VERSION],
  [
    // Solaris
    /(sunos)\s?([\w\.\d]*)/i // Solaris
  ],
  [[NAME, 'Solaris'], VERSION],
  [
    // BSD based
    /\s([frentopc-]{0,4}bsd|dragonfly)\s?([\w\.]*)/i // FreeBSD/NetBSD/OpenBSD/PC-BSD/DragonFly
  ],
  [NAME, VERSION],
  [
    /(haiku)\s(\w+)/i // Haiku
  ],
  [NAME, VERSION],
  [
    /cfnetwork\/.+darwin/i,
    /ip[honead]{2,4}(?:.*os\s([\w]+)\slike\smac|;\sopera)/i // iOS
  ],
  [
    [VERSION, /_/g, '.'],
    [NAME, 'iOS']
  ],
  [
    /(mac\sos\sx)\s?([\w\s\.]*)/i,
    /(macintosh|mac(?=_powerpc)\s)/i // Mac OS
  ],
  [
    [NAME, 'Mac OS'],
    [VERSION, /_/g, '.']
  ],
  [
    // Other
    /((?:open)?solaris)[\/\s-]?([\w\.]*)/i, // Solaris
    /(aix)\s((\d)(?=\.|\)|\s)[\w\.])*/i, // AIX
    /(plan\s9|minix|beos|os\/2|amigaos|morphos|risc\sos|openvms|fuchsia)/i,
    // Plan9/Minix/BeOS/OS2/AmigaOS/MorphOS/RISCOS/OpenVMS/Fuchsia
    /(unix)\s?([\w\.]*)/i // UNIX
  ],
  [NAME, VERSION]
];

export default {
  parser: function () {
    var ua =
      window && window.navigator && window.navigator.userAgent
        ? window.navigator.userAgent
        : EMPTY;
    var OS = { name: undefined, version: undefined };
    var BROWSER = { name: undefined, version: undefined };
    mapper.rgx.call(OS, ua, os);
    mapper.rgx.call(BROWSER, ua, browser);
    BROWSER.major = util.major(BROWSER.version);
    return {
      OS,
      BROWSER
    };
  }
};
